<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog title="" :visible.sync="dialogVisible_detail" custom-class="cus_dialog cus_dialogFullHeight" width="90%"
      :close-on-click-modal="false" @close="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 100%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="110px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">{{EditItem.username}}</el-form-item>

                <el-form-item label="所在部门:">{{ EditItem.dept_name||'-' }}</el-form-item>

                <el-form-item label="申请时间:">{{ EditItem.cdate }}</el-form-item>

                <el-form-item label="入库单号:">{{EditItem.rk_num}}</el-form-item>
                <el-form-item v-if="EditItem.sg_num" label="申购单号:">{{EditItem.sg_num}}</el-form-item>
                <el-form-item v-if="EditItem.sg_num" label="单据日期:">{{EditItem.sg_cdate||'-'}}</el-form-item>
                <el-form-item label="入库仓库:" prop="zc_storeid">{{EditItem.storename}}</el-form-item>
                <el-form-item label="仓库管理员:">{{EditItem.store_admin_name}}</el-form-item>

                <el-form-item label="金额合计(元):">{{EditItem.howmuch_rk}}</el-form-item>
                <el-form-item label="入库备注:">
                  <el-input type="textarea" :rows="5" :value="EditItem.rk_remark||'-'" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item label="相关图片:">
                  <div class="flexStart">
                    <div v-if="EditItem.files&&EditItem.files.length>0" class="updimage"
                      v-for="(f, idx) in EditItem.files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div v-if="!EditItem.files||EditItem.files.length==0" class="">-</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table ref="mySGtable" :data="EditItem.hc_list" height="100%" :row-key=" (row) => { return row.id;  }"
            stripe border style="width:fit-content;width:100%;">

            <el-table-column :key="Math.random()" type="index" label="序号" width="55" align="center" />
            <el-table-column prop="title" label="耗材名称" show-overflow-tooltip width="200px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">耗材名称</span>
              </template>
            </el-table-column>
            <el-table-column prop="pinpai" label="品牌" width="100px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">品牌</span>
              </template>
            </el-table-column>
            <el-table-column prop="xinghao" label="型号" width="100px" align="center">
              <template slot="header">
                <span style="color: #F56C6C">型号</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="cgJine" width="150"
              :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">采购单价(元)</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{formatter(null,null,scope.row.cgJine)}}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="cgNum" width="150"
              :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">采购数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{scope.row.cgNum}}{{scope.row.jldw}}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="cgTotal" label="采购总价(元)"
              :show-overflow-tooltip="true" width="100" align="center" :formatter="formatter"  />
            <el-table-column :key="Math.random()" prop="jine" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">入库单价(元)</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{formatter(null,null,scope.row.rkJine)}}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="jine" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">入库数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{scope.row.rkNum}}{{scope.row.jldw}}</span>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" prop="rkTotal" label="入库总价(元)" :show-overflow-tooltip="true"
              width="150" align="center" :formatter="formatter" />

            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="user_remark" label="备注"
              :show-overflow-tooltip="true" width="200" />
            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="img" label="图片" width="200">
              <template slot-scope="scope">
                <div class="flexStart">
                  <div v-if="scope.row.img" class="updimage"
                    style="position: relative;margin-top:10px;width: 100px;height: 100px;">
                    <img :src="scope.row.img" @click="handlePictureCardPreview(scope.row.img)"
                      style="width: 100px;height: 100px;cursor:pointer;">
                  </div>
                  <div v-else class="">-</div>

                </div>
              </template>
            </el-table-column>
            <el-table-column :key="Math.random()" v-if="EditItem.sg_num" prop="externalLinks" label="链接" width="250">
              <template slot-scope="scope">
                <div v-if="scope.row.links && scope.row.links.length > 0" v-for="(item, index) in scope.row.links">
                  <el-tooltip :enterable="false" :disabled="!item||(item&&item.length<28)" class="item" effect="dark"
                    placement="top-start">
                    <div slot="content" style="width: 400px;">{{ item }}</div>
                    <el-link :href="item" target="_blank" type="primary">
                      <div class="overOneLine" style="width: 180px;">{{ item }}</div>
                    </el-link>
                  </el-tooltip>
                </div>
                <div v-if="!scope.row.links||scope.row.links.length==0">-</div>
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div slot="footer">
        <div v-if="EditItem.sp_file && EditItem.sp_file.length > 0" class="flexStart" style="margin-bottom: 10px;">
          <span style="margin-left: 10px;">附件：</span>
          <el-image v-for="(file,index) in EditItem.sp_file" :key="index" :src="file.src" fit="contain"
            :preview-src-list="imgSrcList" style="width: 100px;height: 100px;margin-right: 10px;"></el-image>
        </div>
        <div class="flexStart" style="">
          <span v-if="EditItem.sp_status>0" style="white-space: nowrap;margin-left: 10px;">入库意见：</span>
          <el-input v-if="EditItem.sp_status>0" v-model="EditItem.sp_remark" size="small" style="margin-right:10px;"
            disabled></el-input>
          <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      disabled: false,
      files:[],
      arr_json:[],
      imgSrcList: [],
    }
  },
  props:['dialogVisible_detail','dialogTitle','EditItem'],
  mounted() {
    if(this.EditItem.sp_file){
      this.EditItem.sp_file = JSON.parse(this.EditItem.sp_file)
      if (this.EditItem.sp_file && this.EditItem.sp_file.length) {
        this.EditItem.sp_file.forEach(item => {
          this.imgSrcList.push(item.src)
        })
      }
    }
    console.log(174,this.EditItem)
  },
  methods: {
    formatter(row,col,cellValue, index){
      return cellValue ? Number(cellValue).toFixed(2) : cellValue
    },
    handlePictureCardPreview(src){
      console.log(this.$parent)
      this.$$parent(this,'handlePictureCardPreview',src)
    },
    onClose(){
      this.$$parent(this,'dialogVisible_detail',false)
    }
  }
}
</script>


